<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="45%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.name }">
							<label>Nombre</label>
							<el-input v-model="form.name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.printer_ip }">
							<label>IP de impresora</label>
							<el-input v-model="form.printer_ip"></el-input>
							<small
								class="error--text"
								v-if="errors.printer_ip"
								v-text="errors.printer_ip[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<div class="form-actions text-right mt-3 mb-3">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</div>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'command-zones',
			valid: false,
			errors: {},
			form: {},
		};
	},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				name: null,
				printer_ip: null,
			};
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar zona' : 'Nueva zona';
			await this.initForm();
			await this.getRecord();
		},
		getRecord() {
			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form = response.data.data;
					this.form.connection_type = this.connectionType;
				});
			}
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
